import "./App.css";

function App() {
  return (
    <>
      <div className="nothing">
        <h1>Nothing to see here...yet.</h1>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <a href="https://osmanduysak.com">
        <div className="landingpagebox">
          <h2>osmanduysak.com</h2>
        </div>
      </a>
      <footer>&copy; 2024 Duysak Developers.</footer>
    </>
  );
}

export default App;
